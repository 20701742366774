<template>
  <div class="animated fadeIn">
    <!--  <b-btn variant="primary" @click=getCmd() size="sm">Commands</b-btn> -->
    <template v-if="isAdmin(role_id)">
      <b-btn variant="success" @click="newMachine()" size="lg">{{
        $t("message.newMachine")
      }}</b-btn
      ><br /><br />
    </template>
    <b-card :title="$t('message.allMachine')">
      <b-form @submit.prevent="searchFn">
        <div class="row">
          <div class="col-lg-8 my-1">
            <label class="col-sm-6 col-md-4 col-lg-4 my-1">
              {{ $t("message.type") }} : <br />
              <b-form-select
                v-model="searchData.typeId"
                :options="$t('message.arrays.machineTypeId')"
              />
            </label>

            <label class="col-sm-6 col-md-8 col-lg-8 my-1">
              {{ $t("message.machineId") }} : <br />
              <multi-list-select
                :list="machineData"
                option-value="machineId"
                option-text="name"
                :selected-items="selectedMachine"
                placeholder="All"
                @select="onSelectMachine"
              >
              </multi-list-select>
            </label>

            <label class="col-sm-6 col-md-4 col-lg-4 my-1">
              {{ $t("message.machineSerial") }} : <br />
              <b-form-input v-model="searchData.serial" />
            </label>

            <label class="col-sm-6 col-md-4 col-lg-4 my-1">
              {{ $t("message.version") }} : <br />
              <b-form-input v-model="searchData.version" />
            </label>

            <label class="col-sm-6 col-md-4 col-lg-4 my-1">
              {{ $t("message.onlineOfline") }} : <br />
              <b-form-select
                v-model="searchData.online"
                :options="$t('message.arrays.onlineOfline')"
              />
            </label>
          </div>

          <label class="col-sm-12 col-md-3 col-lg-2 my-1">
            <br /><b-btn
              variant="primary"
              class="col-sm-12 col-md-12"
              type="submit"
              ><i class="fa fa-search"></i>&nbsp;{{
                $t("message.search")
              }}</b-btn
            >
          </label>

          <!-- <div class="col-sm-6 col-md-2">
            ซีเรียลตู้ : <br>
            <b-form-input v-model="searchData.serial"/>
          </div> -->
        </div> </b-form
      ><br />
      <div class="table-responsive">
        <table class="table2">
          <thead class="bg-primary" style="text-align: center">
            <tr>
              <th>{{ $t("message.machineId") }}</th>
              <th>{{ $t("message.type") }}</th>
              <th>{{ $t("message.machineSerial") }}</th>
              <th>{{ $t("message.machineModel") }}</th>
              <th>{{ $t("message.machineName") }}</th>
              <th>{{ $t("message.moneyInbox") }}</th>
              <th>{{ $t("message.status") }}</th>
              <th>{{ $t("message.version") }}</th>
              <th>{{ $t("message.lastOnline") }}</th>
              <th></th>
              <th v-if="$isRole('owner', role_id)"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in machines">
              <tr>
                <td style="text-align: center">{{ item.id }}</td>
                <td style="text-align: center">{{ item.model.type.name }}</td>
                <td>
                  <nav style="width: 140px">{{ item.serial }}</nav>
                </td>
                <td>{{ item.model.name }}</td>
                <td>
                  <nav style="width: 140px">{{ item.name }}</nav>
                </td>
                <td style="text-align: right">
                  <template v-if="item.inbox"
                    ><span class="spanCustom" @click="showInboxModal(item)"
                      ><nav style="width: 80px">
                        {{ Number(item.inbox.total).toLocaleString() }}
                      </nav></span
                    ></template
                  >
                </td>
                <template v-if="now - new Date(item.lastOnline) <= 1800000"
                  ><td style="text-align: center">
                    <span class="badge badge-success" style="font-size: 100%">{{
                      $t("message.online")
                    }}</span>
                  </td></template
                >
                <template v-else
                  ><td style="text-align: center">
                    <span class="badge badge-danger" style="font-size: 100%">{{
                      $t("message.offline")
                    }}</span>
                  </td></template
                >
                <td style="text-align: right">{{ item.version }}</td>
                <td style="text-align: right">
                  <nav style="width: 140px">
                    {{ item.lastOnline | moment("YYYY-MM-DD HH:mm") }}
                  </nav>
                </td>
                <td>
                  <b-btn
                    variant="primary"
                    @click="details(item)"
                    size="sm"
                    style="width: 80px"
                    >{{ $t("message.deviceDetails") }}</b-btn
                  >
                </td>
                <td v-if="$isRole('owner', role_id)">
                  <b-btn
                    variant="warning"
                    @click="settings(item)"
                    size="sm"
                    style="width: 80px"
                    >{{ $t("message.setting") }}</b-btn
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <br />
      <Page
        :total="totalRows"
        :current="Number(searchData.page)"
        :page-size="searchData.rows"
        @on-change="pageChanged"
      />
    </b-card>
    <b-modal ref="detailsModal" hide-footer hide-header size="lg">
      <div class="table-responsive">
        <table class="table-modal" align="center">
          <template v-if="modalData.type === 'washing'">
            <thead style="text-align-last: center">
              <th>{{ $t("message.deviceId") }}</th>
              <th>{{ $t("message.enable") }}</th>
              <th>{{ $t("message.status") }}</th>
              <th>{{ $t("message.machineType") }}</th>
            </thead>
            <template v-for="items in modalData">
              <tr align="center">
                <td>{{ items.id }}</td>
                <td width="30%">
                  <template v-if="items.enable === true">
                    <span class="badge badge-success" style="font-size: 100%">{{
                      $t("message.enable")
                    }}</span>
                  </template>

                  <template v-else-if="items.enable === false">
                    <span class="badge badge-danger" style="font-size: 100%">{{
                      $t("message.disable")
                    }}</span>
                  </template>
                </td>

                <td>
                  <template v-if="items.status === 'WASHING'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.washing")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'READY'">
                    <span class="badge badge-success" style="font-size: 100%">{{
                      $t("message.ready")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'ERROR'">
                    <span class="badge badge-danger" style="font-size: 100%">{{
                      $t("message.machineError")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'UNKNOW'">
                    <span
                      class="badge badge-secondary"
                      style="font-size: 100%"
                      >{{ $t("message.deviceStatus.UNKNOW") }}</span
                    >
                  </template>

                  <template v-else-if="items.status === 'BUSY'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'MC_FAILED'">
                    <span class="badge badge-danger" style="font-size: 100%">{{
                      $t("message.deviceStatus.MC_FAILED")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'COMMU_FAILED'">
                    <span class="badge badge-danger" style="font-size: 100%">{{
                      $t("message.deviceStatus.COMMU_FAILED")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'MC_NOT_SUPPORT'">
                    <span class="badge badge-danger" style="font-size: 100%">{{
                      $t("message.deviceStatus.MC_NOT_SUPPORT")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'BUSY_POWERUP'">
                    <span class="badge badge-danger" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_POWERUP")
                    }}</span>
                  </template>

                  <template
                    v-else-if="items.status === 'BUSY_WAIT_CLOSING_DOOR'"
                  >
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_WAIT_CLOSING_DOOR")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'BUSY_DOOR_OPEN'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_DOOR_OPEN")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'BUSY_WAIT_USER'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_WAIT_USER")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'BUSY_WASH'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_WASH")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'BUSY_RINSE'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_RINSE")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'BUSY_SPIN'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_SPIN")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'BUSY_DRYING'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_DRYING")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'BUSY_COOL_DOWN'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_COOL_DOWN")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'BUSY_DRYER_EXTENSION'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_DRYER_EXTENSION")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'BUSY_FINISH'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_FINISH")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'BUSY_RESERV'">
                    <span class="badge badge-warning" style="font-size: 100%">{{
                      $t("message.deviceStatus.BUSY_RESERV")
                    }}</span>
                  </template>

                  <template v-else-if="items.status === 'CLOSE_SERVICE'">
                    <span
                      class="badge badge-secondary"
                      style="font-size: 100%"
                      >{{ $t("message.deviceStatus.CLOSE_SERVICE") }}</span
                    >
                  </template>

                  <template v-else>
                    <span class="badge badge-secondary" style="font-size: 100%"
                      >UNKNOW</span
                    >
                  </template>
                </td>
                <td>{{ items.deviceDetails.name }}</td>
              </tr>
            </template>
          </template>
          <template v-else>
            <table class="table-modal">
              <thead style="text-align-last: center">
                <th>{{ $t("message.slot") }}</th>
                <th>{{ $t("message.productName") }}</th>
                <th>{{ $t("message.amount") }}</th>
                <th>{{ $t("message.price") }}</th>
              </thead>
              <template v-for="items in modalData">
                <tr style="text-align: center">
                  <td>{{ items.slotId }}</td>
                  <td>{{ items.product.name }}</td>
                  <template v-if="items.enable">
                    <template v-if="items.qty <= 1"
                      ><span style="color: red">{{
                        $t("message.outOfStock")
                      }}</span></template
                    >
                    <template v-else
                      ><td>{{ items.qty }}</td></template
                    >
                  </template>
                  <template v-else
                    ><span style="color: red">{{
                      $t("message.disable")
                    }}</span></template
                  >
                  <td>{{ items.price }}</td>
                </tr>
              </template>
            </table>
          </template>
        </table>
      </div>
    </b-modal>

    <b-modal ref="inboxModal" hide-footer hide-header>
      <b-tabs>
        <b-tab title="เงินในตู้ทั้งหมด">
          <table class="table-modal" style="text-align: center">
            <thead style="text-align-last: center">
              <th>ประเภท</th>
              <th>จำนวน</th>
              <th>เป็นเงิน</th>
            </thead>
            <tbody>
              <tr>
                <th>เหรียญ</th>
                <td></td>
                <td></td>
              </tr>
              <template v-for="(value, key) in inboxData.coin">
                <tr>
                  <td>{{ key }} บาท</td>
                  <td>{{ value }}</td>
                  <td>{{ value * key }} บาท</td>
                </tr>
              </template>
              <tr>
                <th>ธนบัตร</th>
                <td></td>
                <td></td>
              </tr>
              <template v-for="(value, key) in inboxData.bill">
                <tr>
                  <td>{{ key }} บาท</td>
                  <td>{{ value }}</td>
                  <td>{{ value * key }} บาท</td>
                </tr>
              </template>
            </tbody>
            <tfoot style="text-align-last: center">
              <td></td>
              <th>รวมเป็นเงิน</th>
              <th>{{ inboxData.total }} บาท</th>
            </tfoot>
          </table>
        </b-tab>
        <b-tab title="เงินทอน">
          <table class="table-modal" style="text-align: center">
            <thead style="text-align-last: center">
              <th>ประเภท</th>
              <th>จำนวน</th>
              <th>เป็นเงิน</th>
            </thead>
            <tbody>
              <tr>
                <td>เหรียญ</td>
                <td></td>
                <td></td>
              </tr>
              <template v-for="(value, key) in changeData.coin">
                <tr>
                  <td>{{ key }} บาท</td>
                  <td>{{ value }}</td>
                  <td>{{ value * key }} บาท</td>
                </tr>
              </template>
              <tr>
                <th>ธนบัตร</th>
                <td></td>
                <td></td>
              </tr>
              <template v-for="(value, key) in changeData.bill">
                <tr>
                  <td>{{ key }} บาท</td>
                  <td>{{ value }}</td>
                  <td>{{ value * key }} บาท</td>
                </tr>
              </template>
            </tbody>
            <tfoot style="text-align-last: center">
              <td></td>
              <th>รวมเป็นเงิน</th>
              <th>{{ changeData.total }} บาท</th>
            </tfoot>
          </table>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import webServices from "../../script";
import DatePicker from "../../custom_modules/vue2-datepicker";
import {
  ModelListSelect,
  MultiListSelect,
} from "../../custom_modules/search-select";
export default {
  name: "orders",
  components: {
    DatePicker,
    ModelListSelect,
    MultiListSelect,
  },
  data() {
    return {
      now: new Date(),
      role_id: window.localStorage.getItem("roleID"),
      rowData: [],
      totalRows: 1,
      searchData: {
        page: 1,
        rows: 20,
        userId: window.localStorage.getItem("userId"),
        modelId: "",
        typeId: "",
        mode: "",
        machineId: "",
        machineIdBefor: "",
      },
      selectedMachine: [],
      machineData: [],
      machines: [],
      modalData: {},
      inboxData: "",
      changeData: {
        coin: "",
        bill: "",
        total: 0,
      },
      type: [
        { value: "", text: "ทั้งหมด" },
        { value: 3, text: "ฟาร์มซักผ้า" },
        { value: 1, text: "ตู้น้ำ" },
        { value: 2, text: "ตู้น้ำมัน" },
      ],
    };
  },
  mounted() {
    console.log(this.$route.query.page);

    if (!this.$route.query.page) {
      this.$router.replace({ name: "Machines", query: { page: 1 } });
    }

    this.searchData.page = this.$route.query.page;
    this.getMachine();
    this.getMachineList();
    // this.getDeviceType()
  },
  methods: {
    isAdmin(role) {
      return webServices.isAdmin(role);
    },
    newMachine() {
      this.$router.push("/machine/new_machine");
    },
    searchFn() {
      // console.log('searchFn', this.searchData)
      // console.log('this.searchData.machineIdBefor ', this.searchData.machineIdBefor )
      // console.log('this.searchData.machineId', this.searchData.machineId)
      if (this.searchData.machineIdBefor != this.searchData.machineId) {
        this.searchData.page = 1;
        console.log("change page is 1");
      }
      // console.log('after this.searchData', this.searchData)
      this.getMachine(this.searchData);
    },
    settings(data) {
      this.$router.push({
        path: `/machine/setting/${data.id}`,
        params: { id: data.id },
        query: { page: this.$route.query.page },
      });
    },
    onSelectMachine(items, lastSelectItem) {
      this.searchData.machineIdBefor = this.searchData.machineId;
      // console.log(items)
      this.selectedMachine = items;
      this.searchData.machineId = this.selectedMachine.map(
        (data) => data.machineId
      );
    },
    showInboxModal(data) {
      function slice(object, keys) {
        return Object.keys(object)
          .filter(function (key) {
            return keys.indexOf(key) >= 0;
          })
          .reduce(function (acc, key) {
            acc[key] = object[key];
            return acc;
          }, {});
      }
      let coin = slice(data.inbox, ["1", "2", "5", "10"]);
      let bill = slice(data.inbox, ["20", "50", "100", "500", "1000"]);
      this.inboxData = {
        coin: coin,
        bill: bill,
        total: data.inbox.total,
      };
      // console.log(data.change)
      if (data.change) {
        let total = 0;
        // this.changeData.coin = data.change.coin
        // this.changeData.bill = data.change.bill
        const inbox = Object.assign(data.change.coin, data.change.bill);
        Object.keys(inbox).forEach((key) => {
          total += key * inbox[key];
        });
        let coinC = slice(data.change.coin, ["1", "2", "5", "10"]);
        let billC = slice(data.change.bill, ["20", "50", "100", "500", "1000"]);
        this.changeData = {
          coin: coinC,
          bill: billC,
          total: total,
        };
      }
      this.$refs.inboxModal.show();
    },
    details(data) {
      this.$Progress.start();
      if (data.model.type.name === "washing") {
        webServices.getDevice(data.id).then((res) => {
          this.$Progress.finish();
          // console.log(res.data)
          this.modalData = res.data;
          this.modalData.type = "washing";
          this.$refs.detailsModal.show();
        });
      } else {
        webServices.getSlots(data.id).then((res) => {
          this.$Progress.finish();
          // console.log(res.data)
          this.modalData = res.data;
          this.modalData.type = "other";
          this.$refs.detailsModal.show();
        });
      }
    },
    getMachine() {
      this.$Progress.start();
      this.searchData.machineIdBefor = this.searchData.machineId;

      this.$router.replace({
        name: "Machines",
        query: { page: this.searchData.page },
      });

      webServices
        .getMachine(this.searchData)
        .then((res) => {
          this.$Progress.finish();
          this.totalRows = res.data.count;
          this.machines = res.data.rows;
        })
        .catch((err) => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log("error @MACHINE");
          console.log(err);
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText,
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText),
          });
        });
    },
    getMachineList() {
      this.$Progress.start();
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then((res) => {
          this.$Progress.finish();
          this.machineData = res.data;
        })
        .catch((err) => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @machine");
          console.log(err);
        });
    },
    pageChanged(value) {
      // window.scrollTo(0, 0)
      this.searchData.page = value;
      this.searchFn();
    },
  },
};
</script>
<style></style>
